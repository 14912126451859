
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from 'next';
import type { ApplicationProperties } from 'source/utilities/ui';
import styled from '@emotion/styled';
import { Main } from 'source/components/main';
import { Content } from 'source/components/content';
import useTranslation from 'next-translate/useTranslation';
import { SeoProvider } from 'library/components/seo-provider';
const StyledContent = styled(Content) `
  width: 100%;

  justify-content: center;
  align-items: center;
`;
interface NotFoundProperties extends ApplicationProperties {
}
const NotFoundPage: NextPage<NotFoundProperties> = () => {
    const { t } = useTranslation('404');
    return (<SeoProvider config={{
            title: t('seo.title'),
            description: t('seo.description'),
            keywords: t('seo.keywords'),
            author: t('seo.author'),
        }}>
      <Main>
        <StyledContent>
          <h1>Страница не найдена</h1>
        </StyledContent>
      </Main>
    </SeoProvider>);
};
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__195d25bb5cb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d25bb5cb__ as getStaticProps }
  